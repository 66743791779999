import moment from "moment-timezone";
import {
    CampaignsFiltersType,
    OrdersMotoFiltersType,
} from "../../Interfaces/Campaigns/formData";
import { CustomersFiltersType } from "../../Interfaces/Customers/formData";
import {
    FailedNotificationsFiltersType,
    OrdersFiltersType,
} from "../../Interfaces/Payments/formData";
import { DevicesFiltersType } from "../../Interfaces/Redux/sliceStateTypes";
const monthsByDefault = 1;
const daysByDefault = 7;
export const getOrdersInitialState = (): OrdersFiltersType => ({
    endingDate: moment().endOf("day").format(),
    startingDate: moment()
        .subtract(daysByDefault, "days")
        .startOf("day")
        .format(),
    additional: null,
    minAmount: null,
    maxAmount: null,
    externalId: null,
    operative: null,
    serviceUUID: null,
    status: null,
    isSafe: null,
    orderType: null,
    reference: null,
    orderTags: null,
    sourceTypes: null,
    transactionError: null,
    processorID: null,
    authorization: null,
});
export const getCustomersInitialState = (): CustomersFiltersType => ({
    endingDate: moment().endOf("day").format(),
    startingDate: moment()
        .subtract(monthsByDefault, "month")
        .startOf("day")
        .format(),
    externalId: null,
});
export const getCampaignsInitialState = (): CampaignsFiltersType => ({
    createdAtEnd: moment().endOf("day").format(),
    createdAtStart: moment()
        .subtract(monthsByDefault, "month")
        .startOf("day")
        .format(),
    description: null,
    expiresAtEnd: null,
    expiresAtStart: null,
    filename: null,
    serviceUUID: null,
    subject: null,
    type: null,
});
export const getOrdersMotoInitialState = (): OrdersMotoFiltersType => ({
    createdAtEnd: moment().endOf("day").format(),
    createdAtStart: moment()
        .subtract(monthsByDefault, "month")
        .startOf("day")
        .format(),
    additional: null,
    certified: null,
    destination: null,
    detailUUID: null,
    externalID: null,
    isPaid: null,
    maxAmount: null,
    minAmount: null,
    operative: null,
    orderType: null,
    paymentStatus: null,
    serviceUUID: null,
});
export const getFailedNotificationsInitialState =
    (): FailedNotificationsFiltersType => ({
        endingDate: moment().endOf("day").format(),
        startingDate: moment()
            .subtract(monthsByDefault, "month")
            .startOf("day")
            .format(),
        uuid: null,
    });
export const getDevicesInitialState = (): DevicesFiltersType => ({
    identifier: null,
    service: null,
    terminal_id: null,
    communication_type: null,
    active: null,
    configured: null,
    created_at_from: null,
    created_at_to: null,
    updated_at_from: null,
    updated_at_to: null,
});
